import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Layout from "../Layouts";
import Home from "../pages/home/Home";
import Blog from "../pages/blogs/Blog";
import Coaching from "../pages/coaching/Coaching";
import CommonMainRegForm from "../pages/events/CommonEvents/CommonMainRegForm";
import StudyAbroad from "../pages/studyAbroad/StudyAbroad";
import SubBlog from "../pages/subblog/SubBlog";
import Thankyou from "../pages/thankyou/Thankyou";
import About from "../pages/aboutUs/About";
import IeltsOnline from "../pages/ieltsOnline/IeltsOnline";
import SummerCamp from "../pages/summerCamp/SummerCamp";
import ContactUs from "../pages/contactUs/ContactUs";
import News from "../pages/news/News";
import SubNews from "../pages/subnew/SubNews";
// import MainEventReg from "../pages/events/CoffeeWithCareearline/MainEventReg";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import SpinnerWheel from "../pages/events/CoffeeWithCareearline/SpinnerWheel";
import EventThankyou from "../pages/events/CoffeeWithCareearline/EventThankyou";
import CWCThankyou from "../pages/thankyou/CWCThankyou";
import WebinarRegForm from "../pages/events/Webinar/WebinarRegForm";
import EducationExpoMainRegForm from "../pages/events/EducationExpoEvents/EducationExpoMainRegForm";
import PageNotFound from "../Components/Common/PageNotFound";
import AddmissionWeekRegForm from "../pages/events/AdmissionWeek/AddmissionWeekRegForm";
import Header from "../Layouts/Header/Header";
import USALEventLPMainPage from "../pages/events/CommonEvents/USALEventLPMainPage";
import IELTSLandingPage from "../pages/ielts/IELTSLandingPage";
import IELTSLandingPage2 from "../pages/ielts2/IELTSLandingPage2";
import GRELandingPage from "../pages/gre/GRELandingPage";
import PTELandingPage from "../pages/pte/PTELandingPage";
import SATLandingPage from "../pages/sat/SATLandingPage";
import CWCEventPage from "../pages/events/CoffeeWithCareearline/CWCEventPage";
import CWCEventPage16Nov from "../pages/events/CoffeeWithCareearline16Nov/CWCEventPage";
import MegaSeminar from "../pages/events/megaseminar/MegaSeminar";
import IELTSLandingPage3 from "../pages/ielts3/IELTSLandingPage3";
// import CommonMainRegFormWithBanner from "../pages/events/CommonEvents/CommonMainRegFormWithBanner";

const Index = () => {
  return (
    <Routes>
      {/* <Route
        path="events/megaseminar/:Campaign"
        element={
          <>
            <MegaSeminar />
          </>
        }
      />
      <Route
        path="events/megaseminar/"
        element={
          <>
            <MegaSeminar />
          </>
        }
      /> */}
      <Route
        path="events/worldeducationfair/:Campaign/:StudentID"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair/:Campaign/"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      {/* <Route
        path="events/worldeducationfair/anand/:Campaign/:StudentID"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair/anand/:Campaign/"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair/anand"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      /> */}
      <Route
        path="events/worldeducationfair/surat/:Campaign/:StudentID"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair/surat/:Campaign/"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      <Route
        path="events/worldeducationfair/surat"
        element={
          <>
            <Header />
            <CommonMainRegForm />
          </>
        }
      />
      {/* <Route
        path="events/usauniversityseminar/:Campaign/:StudentID"
        element={
          <>
            <Header />
            <USALEventLPMainPage />
          </>
        }
      />
      <Route
        path="events/usauniversityseminar/:Campaign/"
        element={
          <>
            <Header />
            <USALEventLPMainPage />
          </>
        }
      />
      <Route
        path="events/usauniversityseminar"
        element={
          <>
            <Header />
            <USALEventLPMainPage />
          </>
        }
      /> */}
      <Route
        path="events/coffeewithcareerline/:Campaign"
        element={
          <>
            <CWCEventPage />
          </>
        }
      />
      <Route
        path="events/coffeewithcareerline/"
        element={
          <>
            <CWCEventPage />
          </>
        }
      />
      {/* <Route
        path="events/coffeewithcareerline2"
        element={
          <>
            <CWCEventPage2 />
          </>
        }
      /> */}
      <Route
        path="events/ieltsmocktest/"
        element={
          <>
            {/* <IELTSLandingPage /> */}
            {/* <IELTSLandingPage2 /> */}
            <Header />
            <IELTSLandingPage3 />
          </>
        }
      />
      <Route
        path="events/ieltsmocktest/:Campaign/"
        element={
          <>
            {/* <IELTSLandingPage /> */}
            {/* <IELTSLandingPage2 /> */}
            <Header />
            <IELTSLandingPage3 />
          </>
        }
      />
      <Route
        path="gre"
        element={
          <>
            <GRELandingPage />
          </>
        }
      />
      <Route
        path="sat"
        element={
          <>
            <SATLandingPage />
          </>
        }
      />
      <Route
        path="pte"
        element={
          <>
            <PTELandingPage />
          </>
        }
      />
      <Route
        path="ielts"
        element={
          <>
            <IELTSLandingPage />
          </>
        }
      />

      {/* <Route
        path="events/ieltsmocktestnew/"
        element={
          <>
            <>
              <Header />
              <IELTSLandingPage3 />
            </>
          </>
        }
      />
      <Route
        path="events/ieltsmocktestnew/:Campaign/"
        element={
          <>
            <>
              <Header />
              <IELTSLandingPage3 />
            </>
          </>
        }
      /> */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about/" element={<About />} />
        {/* <Route
          path="events/coffeewithcareearline/:Campaign/"
          element={<MainEventReg />}
        /> */}
        <Route
          path="events/coffeewithcareearline/thankyou/"
          element={<CWCThankyou />}
        />
        {/* <Route
          path="events/worldeducationfair/:Campaign/"
          element={<CommonMainRegForm />}
        />
        <Route
          path="events/worldeducationfair/:Campaign/:StudentID"
          element={<CommonMainRegForm />}
        /> */}
        <Route
          path="events/admissionweek/:Branch/:Campaign/"
          element={<AddmissionWeekRegForm />}
        />
        <Route
          path="events/educationexpo/:Campaign/"
          element={<EducationExpoMainRegForm />}
        />
        <Route path="events/webinar/:Campaign/" element={<WebinarRegForm />} />
        <Route
          path="events/educationexpo/:Campaign/:StudentID"
          element={<EducationExpoMainRegForm />}
        />
        <Route
          path="study-in-canada/"
          element={<Navigate to="/studyabroad/study-in-canada/" />}
        />
        <Route
          path="study-in-usa/"
          element={<Navigate to="/studyabroad/study-in-usa/" />}
        />
        <Route
          path="united-kingdom/"
          element={<Navigate to="/studyabroad/study-in-unitedkingdom/" />}
        />
        <Route
          path="australia/"
          element={<Navigate to="/studyabroad/study-in-australia/" />}
        />
        <Route
          path="new-zealand/"
          element={<Navigate to="/studyabroad/study-in-newzealand/" />}
        />
        <Route path="studyabroad/:countryname/" element={<StudyAbroad />} />
        <Route
          path="ielts-coaching/"
          element={<Navigate to="/coaching/ielts/" />}
        />
        <Route
          path="gre-coaching/"
          element={<Navigate to="/coaching/gre/" />}
        />
        {/* <Route path="toefl/" element={<Navigate to="/coaching/toefl/" />} /> */}
        <Route
          path="pte-training/"
          element={<Navigate to="/coaching/pte/" />}
        />
        <Route
          path="sat-coaching/"
          element={<Navigate to="/coaching/sat/" />}
        />
        <Route path="coaching/:coachingname/" element={<Coaching />} />
        <Route path="summercamp/" element={<SummerCamp />} />
        <Route path="blogs/" element={<Blog />} />
        <Route path="news/" element={<News />} />
        <Route path="contactus/" element={<ContactUs />} />
        <Route path="contact-us/" element={<Navigate to="/contactus/" />} />
        <Route path="ieltsonline/" element={<IeltsOnline />} />
        <Route path="ecounselling/" element={<ContactUs />} />
        <Route path="blogdetail/:url/" element={<SubBlog />} />
        <Route path="newsdetail/:url/" element={<SubNews />} />
        <Route path="thankyou/" element={<Thankyou />} />
        <Route path="events/thankyou/" element={<EventThankyou />} />
        <Route path="ielts-purchase/" element={<Thankyou />} />
        <Route path="events/rewards/" element={<SpinnerWheel />} />
        <Route path="privacy/" element={<PrivacyPolicy />} />
        <Route path="privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Index;
