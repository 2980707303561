import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./IELTSLandingPage3Style.css";

import IELTSMOCKTEST from "../../assets/image/IELTS EXAM/ieltsmocktest.jpg";
import IELTSMOCKTEST2 from "../../assets/image/IELTS EXAM/ieltsmocktest2.jpg";
import ieltsmocktest4 from "../../assets/image/IELTS EXAM/ieltsmocktest4.jpg";
import PROCESS1 from "../../assets/image/IELTS EXAM/2407availabel.png";
import registerprocess1 from "../../assets/image/IELTS EXAM/registerprocess1.png";
import registerprocess2 from "../../assets/image/IELTS EXAM/registerprocess2.png";
import registerprocess3 from "../../assets/image/IELTS EXAM/registerprocess3.png";
import registerprocess4 from "../../assets/image/IELTS EXAM/registerprocess4.png";

import registration from "../../assets/image/IELTS EXAM/mobile registration.jpg";
import realexam from "../../assets/image/IELTS EXAM/test3.png";
import result from "../../assets/image/IELTS EXAM/result in 48hours.png";
import expertadvice from "../../assets/image/IELTS EXAM/expert advice.png";

import manthinking from "../../assets/image/IELTS EXAM/manthinking.png";
import firstattempt from "../../assets/image/IELTS EXAM/firstattempt.png";
import reattempt from "../../assets/image/IELTS EXAM/reattempt.png";
import logo from "../../assets/image/logo.png";

import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { TbDeviceIpadQuestion } from "react-icons/tb";
import { TbWorldWww } from "react-icons/tb";
import { LiaUserEditSolid } from "react-icons/lia";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlineContactMail } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import { BiSolidConversation } from "react-icons/bi";
import { ImLocation } from "react-icons/im";
import { FaArrowUpRightDots } from "react-icons/fa6";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Element, Link } from "react-scroll";
import IELTSLandingPage3Form from "./IELTSLandingPage3Form";

const testimonialData = [
  {
    id: 1,
    name: "Vidhi Doshi",
    score: 7,
    description: `My overall experience with Careerline was amazing. From preparing for the IELTS to applying for universities and handling the USA visa procedure, everything went smoothly thanks to their guidance and support.
I especially want to thank Jinesh Sir, Jay Sir, and Hozefa Sir for guiding me through the entire process whenever I was stuck or confused. Your advice was precise, and I had to make no effort in applying for universities and visas as Careerline took care of everything.
Additionally, I want to thank Krupa Mam, Neha Mam, and Swati Mam for their guidance during my IELTS journey. Initially, I was very nervous because I didn’t had four months to prepare and needed to complete the IELTS preparation in just one month. However, with focused efforts on my weak points, I was able to score 7 bands overall. I also want to thank Sheetal Mam for making the visa process go smoothly. She helped me gain the confidence that led to my visa approval on the first attempt.
Lastly, I appreciate the dedication and effort the Careerline team provided. They guided me at every step and made the entire process worthwhile. Worth every penny spent`,
  },
  {
    id: 2,
    name: "Badal Patel",
    score: 6.5,
    description: `I highly recommend the IELTS class at Careerline Education Foundation for anyone looking to improve their scores. Whether you’re a beginner or looking to refine your skills, this program is a great choice.The practice materials provided were up-to-date and relevant. I appreciated the variety of resources, including sample papers and mock tests, which closely mimic the actual exam. Also faculties are so friendly and they provide the best IELTS coaching. I appreciate all of their efforts and I'm so glad to have them by my side.`,
  },
  {
    id: 3,
    name: "Sanaaya Besania",
    score: 7,
    description: `My overall experience with Careerline was amazing. The faculty here knows the ins and outs of IELTS and helped all the way to get a wonderful score`,
  },
  {
    id: 4,
    name: "Anu Jat",
    score: 6.5,
    description: `I highly recommend careerline education surat for students who want to achieve their desire bands in IELTS examination because the materials provide here by faculty is up to date and mock tests are taken every week to improvise your score by remarks and strategies given by faculty (My mentor was Swati Saluja Ma’am). Whenever you get stuck at any point just have a word with them your all problems will be solved.`,
  },
  {
    id: 5,
    name: "Dhruvin Sojitra",
    score: 7.5,
    description: `I am delighted to have achieved a very good band score in IELTS, all thanks to the outstanding coaching at Careerline. The training process was highly effective, starting with a clear understanding of the exam format, followed by personalized lessons, regular mock tests, and detailed feedback. The structured approach and expert guidance helped me improve quickly and confidently tackle each module. I highly recommend Careerline to anyone looking for quality IELTS coaching and excellent results!`,
  },
  {
    id: 6,
    name: "Kavya Shah",
    score: 8,
    description: `My experience with careerline has been exhilarating. Both alaknanda ma'am and shikha ma'am supported me unconditionally in my SAT prep. For IELTS, Alaknanda ma'am's training suited me the best and helped me achieve a desirable score.`,
  },
  {
    id: 7,
    name: "Shlok Thakkar",
    score: 8,
    description: `I had an outstanding experience with Careerline! Their IELTS coaching is top-notch, and I’m thrilled to share that I scored 8.0/9.0, all thanks to my amazing teacher, Miss Poonam Sharma. Her guidance and teaching methods were instrumental in my success. Additionally, my counselor, Mr. Hardik Naik, provided invaluable support throughout the entire process of preparing my applications for studying abroad in the USA for my master’s degree. His expertise made the application process smooth and stress-free. I highly recommend Careerline to anyone looking for quality IELTS coaching and expert guidance for studying abroad. They truly go above and beyond!`,
  },
];

const faqData = [
  {
    question: `What is the duration of the IELTS test?`,
    answer: `The IELTS test duration is 2 hours and 45 minutes.`,
  },
  {
    question: `What is the last date to register for the IELTS mock test at Rs. 49?`,
    answer: `Once all available slots are filled, registration will be automatically closed.`,
  },
  {
    question: `Can I pay the registration fee offline?`,
    answer: `No, the registration fee can only be paid online through our websites.`,
  },
  {
    question: "What type of questions can I expect in the IELTS test?",
    answer:
      "The mock exam has a variety of questions similar to the actual one, including multiple-choice, short answer, and essay questions.",
  },
  {
    question: "What is the scoring system for the IELTS test?",
    answer: "The IELTS test uses a band score system that ranges from 0 to 9.",
  },
  {
    question: "How will I receive my IELTS test results?",
    answer: "Your IELTS test results will be available just in 48 hours.",
  },
  {
    question: "What kind of support can I expect from your centre?",
    answer:
      "Our dedicated team will provide you with support and guidance throughout the registration and testing process.",
  },
  {
    question:
      "I already took the IELTS, but my band score was lower; may I retake the test?",
    answer:
      "Yes, 100%, you may retake the examination; also, this practice exam will provide you with a thorough understanding of your areas of weakness and how to improve on each module to receive a good band.",
  },
];

const IELTSLandingPage3 = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="ielts3-section-1 m-0 p-0">
        <div className="container pt-5 pb-5">
          <h1 data-aos="fade-up" data-aos-duration="500">
            Limited Time Offer !
          </h1>
          <div className="row">
            <div className="col-lg-7">
              <p
                className="mb-lg-5 mb-3"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="100"
              >
                Know your capability in IELTS only @ Rs. 49 before spending
                17,000
              </p>
              <div className="position-relative">
                <Link
                  activeClass="active"
                  to="ielts-form"
                  offset={-120}
                  duration={300}
                  className="starburst d-flex d-lg-none"
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <div className="mb-4">
                    IELTS MOCK TEST JUST AT{" "}
                    <span
                      style={{
                        color: "red",
                        textDecoration: "line-through",
                      }}
                    >
                      <span style={{ color: "#000" }}>Rs. </span>499 /-
                    </span>{" "}
                  </div>
                  <span className="offer-line">
                    RS. <span className="offer-price">49/-</span>
                  </span>
                </Link>
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                Are you prepared to begin your journey toward a better future?
                Do you aspire to further your study abroad? Look no further!
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                Careerline Education Foundation is excited to offer an IELTS
                mock test at an unbeatable price of just{" "}
                <span
                  style={{
                    fontSize: "25px",
                    lineHeight: "28px",
                    color: "goldenrod",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  Rs. 49.
                </span>
              </p>
            </div>
            <div className="col-lg-5 d-none d-lg-flex justify-content-end align-items-baseline">
              <Link
                activeClass="active"
                to="ielts-form"
                offset={-120}
                duration={300}
                className="starburst d-none d-lg-flex"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="mb-4">
                  IELTS MOCK TEST JUST AT{" "}
                  <span
                    style={{
                      color: "red",
                      textDecoration: "line-through",
                    }}
                  >
                    <span style={{ color: "#000" }}>Rs. </span>499 /-
                  </span>{" "}
                </div>
                <span className="offer-line">
                  Rs. <span className="offer-price">49/-</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-2 m-0 p-0">
        <div className="container pt-5 pb-5 position-relative">
          <h1 data-aos="fade-up" data-aos-duration="500">
            Upgrade from Band 5 to Band 8
          </h1>
          <FaArrowUpRightDots
            style={{
              position: "absolute",
              fontSize: "150px",
              color: "#224b9a",
              opacity: "0.1",
              top: "15%",
              right: "5%",
            }}
          />
          <div className="row ps-2 pe-2 mb-4">
            <div
              className="col-md-5 upgrade-sec"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <p>
                Take a full-length mock exam to learn what works, what doesn't,
                and how to improve your score.
              </p>
            </div>
          </div>
          <div className="row ps-2 pe-2">
            <h1 data-aos="fade-up" data-aos-duration="500" className="mt-5">
              Who is this test for?
            </h1>
            <div
              className="col-md-6 col-lg-5 p-0 pe-0 pe-md-2"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div class="inner-curve">
                <img src={firstattempt} alt="First Timer" />
                <div>
                  <h3 className="mb-2">First-timers</h3>
                  <p>
                    Understand the IELTS format and the preparation process.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-5 offset-0 offset-lg-2 mt-3 mt-md-0 p-0 ps-0 ps-md-2"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div class="inner-curve">
                <img src={reattempt} alt="Re-attempter" />
                <div>
                  <h3 className="mb-2">Re-attempters</h3>
                  <p>
                    Identify areas of weakness and focus on becoming better.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-3 m-0 p-0">
        <div className="container pt-5 pb-5">
          <h1 data-aos="fade-up" data-aos-duration="500">
            Why IELTS?
          </h1>
          <div className="row pb-2">
            <div className="col-md-6 col-lg-4">
              <img
                src={IELTSMOCKTEST}
                alt="mocktest"
                className="mocktest"
                data-aos="fade-right"
                data-aos-duration="500"
              />
            </div>
            <div className="col-md-6 col-lg-7 offset-0 offset-lg-1">
              <p
                data-aos="fade-up"
                data-aos-duration="500"
                className="mt-3 mt-md-0"
              >
                IELTS (International English Language Testing System) is the
                world's most popular English language proficiency test,
                recognized by over 10,000 organisations globally.
              </p>
              <h1
                data-aos="fade-up"
                data-aos-duration="500"
                className="mt-3 mt-lg-5"
              >
                Why should you attend this mock test?
              </h1>
              <p data-aos="fade-up" data-aos-duration="500">
                Careerline Education Foundation is commited to assisting you in
                achieving your goals. Our testing facilities, professional
                trainers, and unparalleled support ensure that you know where
                you stand and how to get into the excellent band.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-4 m-0 p-0 position-relative">
        <div
          className="container pt-5 pb-5"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h1 data-aos="fade-up" data-aos-duration="500" className="mb-5">
            Why Take This ₹49 IELTS Mock?
          </h1>
          <TbDeviceIpadQuestion className="test-question-icon" />
          <div className="row pb-2">
            <div className="col-12">
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p>
                  <b>Offline Test in an Exam-Like Environment</b> - simulates
                  the real IELTS exam conditions
                </p>
              </div>
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="100"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p>
                  <b>
                    Full-Length Test (Listening, Reading, Writing & Speaking)
                  </b>{" "}
                  - Just like the official test
                </p>
              </div>
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p>
                  <b>Instant Band Score Prediction</b> - Know where you stand
                </p>
              </div>
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p>
                  <b>Expert Evaluation & Feedback</b> - Identify areas of
                  improvement
                </p>
              </div>
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p>
                  <b>Experience Real-Time Exam Pressure</b> - get confident
                  before your actual IELTS
                </p>
              </div>
              <div
                className="list-item"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="500"
              >
                <TbSquareRoundedCheckFilled className="list-icon" />
                <p data-aos="fade-right" data-aos-duration="500">
                  <b>Affordable & Accessible</b> - Only ₹49 (Cheaper than any
                  other test center!)
                </p>
              </div>
              <p className="fw-bold">
                Limited slots available per batch! Secure your spot today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Element className="ielts3-section-5 m-0 p-0" name="ielts-form">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-6 mt-4 mt-lg-0" id="ielts-form">
              <h1
                className="text-start"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                IELTS Score in Just 48 Hours –{" "}
                <span style={{ color: "#FEED00" }}>In Just 49 Rs.</span>
              </h1>
              <p className="m-0" data-aos="zoom-in" data-aos-duration="500">
                Take the mock test, get expert feedback, and see where you stand
                in no time!
              </p>
              <IELTSLandingPage3Form />
            </div>
            <div
              className="col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-end"
              data-aos="fade-left"
              data-aos-duration="500"
            >
              <img src={ieltsmocktest4} alt="mocktest" className="mocktest" />
            </div>
          </div>
        </div>
      </Element>
      <div className="ielts3-section-6 m-0 p-0">
        <div className="container pt-5 pb-5">
          <h1 data-aos="fade-up" data-aos-duration="500">
            What's Included?
          </h1>
          <div className="row pb-2">
            <div className="col-lg-6 mb-3">
              <div
                className="process-card"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <img src={registration} alt="Registration" />
                <div>
                  <h3 className="mb-1">Registration</h3>
                  <p className="m-0">IELTS test registration at just Rs. 49</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div
                className="process-card"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <img src={realexam} alt="realexam" />
                <div>
                  <h3 className="mb-1">Real Exam Simulation</h3>
                  <p className="m-0">
                    Experience the pressure and time of the actual IELTS exam.
                    We will cover all of the modules: listening, reading,
                    writing and speaking.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div
                className="process-card"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <img src={result} alt="result" />
                <div>
                  <h3 className="mb-1">48-Hour Results</h3>
                  <p className="m-0">
                    Receive a detailed band prediction and performance analysis
                    within 48 hours.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div
                className="process-card"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <img src={expertadvice} alt="expertadvice" />
                <div>
                  <h3 className="mb-1">Expert Advice</h3>
                  <p className="m-0">
                    Tips from certified IELTS trainers to optimize your
                    performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-7 m-0 p-0">
        <div className="container pt-5 ">
          <h1 data-aos="fade-up" data-aos-duration="500" className="mb-3">
            Take advantage of this opportunity!
          </h1>
          <div className="row">
            <div className="col-12 mb-5">
              <p data-aos="fade-up" data-aos-duration="500">
                Limited slots are available! Register now and take the first
                step towards achieving your global dreams!
              </p>
            </div>
            <div className="col-12">
              <h1 data-aos="fade-up" data-aos-duration="500">
                How to register?
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#224A98" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 timeline">
              <div
                class="timeline-item mt-5 position-relative"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <TbWorldWww
                  style={{
                    position: "absolute",
                    right: "5%",
                    top: "5%",
                    color: "white",
                    fontSize: "140px",
                    transform: "rotate(15deg)",
                    opacity: "0.1",
                  }}
                />
                <div class="timeline-img">1</div>
                <div class="timeline-content js--fadeInLeft">
                  <h2>
                    <img src={registerprocess1} alt="visit" />
                  </h2>
                  <p>
                    Visit our websites:{" "}
                    <a
                      style={{ wordWrap: "break-word" }}
                      href="https://careerline.org/events/ieltsmocktest/"
                      target="_blank"
                    >
                      (https://careerline.org/events/ieltsmocktest/)
                    </a>
                  </p>
                </div>
              </div>

              <div
                class="timeline-item position-relative"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <LiaUserEditSolid
                  style={{
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                    color: "white",
                    fontSize: "140px",
                    transform: "rotate(-15deg)",
                    opacity: "0.1",
                  }}
                />
                <div class="timeline-img">2</div>
                <div class="timeline-content js--fadeInRight">
                  <h2>
                    <img src={registerprocess2} alt="register" />
                  </h2>
                  <p>Fill out the registration form.</p>
                </div>
              </div>

              <div
                class="timeline-item position-relative"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <GiPayMoney
                  style={{
                    position: "absolute",
                    right: "5%",
                    top: "5%",
                    color: "white",
                    fontSize: "140px",
                    transform: "rotate(15deg)",
                    opacity: "0.1",
                  }}
                />
                <div class="timeline-img">3</div>
                <div class="timeline-content js--fadeInLeft">
                  <h2>
                    <img src={registerprocess3} alt="pay" />
                  </h2>
                  <p>Pay the registration fee of Rs. 49</p>
                </div>
              </div>

              <div
                class="timeline-item position-relative"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <MdOutlineContactMail
                  style={{
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                    color: "white",
                    fontSize: "140px",
                    transform: "rotate(-15deg)",
                    opacity: "0.1",
                  }}
                />
                <div class="timeline-img">4</div>
                <div class="timeline-content js--fadeInRight">
                  <h2>
                    <img src={registerprocess4} alt="mail" />
                  </h2>
                  <p>
                    Receive your confirmation mail and start preparing for the
                    test!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-7" style={{ background: "#CC333F" }}>
        <div className="container pt-3 pb-4 text-center">
          <div
            class="loader-text"
            data-aos="fade-up"
            data-aos-duration="500"
          ></div>
          <div className="row">
            <div className="col-12">
              <p className="m-0" data-aos="fade-up" data-aos-duration="500">
                Register now and unlock your global dreams with the Careerline
                Education Foundation.
              </p>
              <Link
                className="ctc-btn"
                activeClass="active"
                to="ielts-form"
                offset={-120}
                duration={300}
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-8 m-0 p-0 pt-4 pb-4">
        <div className="container">
          <h1 data-aos="fade-up" data-aos-duration="500" className="m-0">
            Testimonials
          </h1>
          <div className="row pb-2">
            <div className="col-12 text-start ">
              <Swiper
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  // 1024: {
                  //   slidesPerView: 3,
                  //   spaceBetween: 10,
                  // },
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {testimonialData?.map((testimonial) => (
                  <SwiperSlide>
                    <>
                      <div
                        className="testimonial"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <div className="testimonial-content">
                          <p>{testimonial?.description}</p>
                        </div>
                        <h3 className="testimonial-title">
                          <a href="#">{testimonial?.name}</a>
                          <small style={{ whiteSpace: "nowrap" }}>
                            {" "}
                            IELTS {testimonial?.score} Band
                          </small>
                        </h3>
                      </div>
                    </>
                  </SwiperSlide>
                ))}
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-9 m-0 p-0">
        <div className="container position-relative pt-4 pb-4">
          <div className="certy-container">
            <div class="certy" data-aos="fade-left" data-aos-duration="500">
              100% <br />
              Doubt <br />
              Clearance
            </div>
          </div>
          <h1 data-aos="fade-up" data-aos-duration="500">
            FAQs
          </h1>
          <p data-aos="fade-up" data-aos-duration="500">
            All your questions, answered in one place! Get the insights you need
            for a smooth IELTS journey.
          </p>
          <div className="row p-3 mt-5 faq-container">
            <div className="col-lg-6">
              <img
                className="man-thinking-img"
                src={manthinking}
                alt="thinking person"
                data-aos="fade-right"
                data-aos-duration="500"
              />
            </div>
            <div className="col-lg-6 p-0">
              <div className="faqs-list">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {faqData?.map((item, index) => {
                    return (
                      <div
                        className="accordion-item"
                        key={index}
                        data-aos="fade-left"
                        data-aos-duration="500"
                        data-aos-delay={`${index}00`}
                      >
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className={`accordion-button ${
                              index === 0 ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-headingOne${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls="flush-collapseOne"
                          >
                            {item?.question}
                          </button>
                        </h2>
                        <div
                          id={`flush-headingOne${index}`}
                          className={`accordion-collapse collapse  ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">{item?.answer}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3 mt-5">
            <div className="col-12" data-aos="zoom-in">
              <div
                style={{
                  background: "rgb(255 255 255 / 18%)",
                  padding: "20px",
                  borderRadius: "8px",
                  textAlign: "center",
                  maxWidth: 500,
                  margin: "auto",
                  boxShadow: "0 0 15px #0000005c",
                }}
              >
                <h3>Still have questions?</h3>
                <p className="text-white text-center mb-4">
                  Can't find the answer you’re looking for? Please contact us.
                </p>
                <Link
                  className="touch-btn"
                  activeClass="active"
                  to="ielts-form"
                  offset={-120}
                  duration={300}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ielts3-section-10 m-0 p-0">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-sm-12 col-lg-8 mb-3 mb-md-0">
              <div
                data-aos="fade-right"
                data-aos-duration="500"
                style={{
                  display: "inline-block",
                  background: "#fff",
                  padding: "3px 9px",
                  borderRadius: "5px",
                }}
              >
                <img src={logo} alt="logo" className="logo" />
              </div>
              <div className="footer-top-para">
                <div
                  className="footer-top-para-right"
                  data-aos="fade-right"
                  data-aos-duration="500"
                >
                  <h3
                    className="m-0 mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <MdContactPhone /> Contact us:
                  </h3>
                  <p className="mb-2">
                    For more information , please contact us at
                  </p>
                  <p className="pb-1">
                    <i className="bi bi-envelope" />
                    <a href="mailto:admin@careerline.org">
                      admin@careerline.org
                    </a>
                  </p>
                  <i className="bi bi-telephone-plus" />
                  <a
                    href="tel:6358989872"
                    // contentEditable="false"
                    style={{ cursor: "pointer" }}
                  >
                    +91 6358 989 872
                  </a>
                </div>
                <div
                  className="footer-top-para-right"
                  data-aos="fade-right"
                  data-aos-duration="500"
                >
                  <h3
                    className="m-0 mt-5 mt-sm-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <BiSolidConversation /> Join the conversation:
                  </h3>
                  <p className="mb-2">
                    Follow us on social media to stay updated on the latest
                    updates, tips and promotions!
                  </p>
                  <div className="footer-socialmedia">
                    <a
                      href="https://www.facebook.com/CareerlineEF/"
                      target="_blank"
                      style={{ background: "#3B5998" }}
                    >
                      <i className="bi bi-facebook" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/careerlineef/"
                      target="_blank"
                      style={{ background: "#0077B5" }}
                    >
                      <i className="bi bi-linkedin" />
                    </a>
                    <a
                      href="https://www.instagram.com/careerlineef/"
                      target="_blank"
                      style={{
                        background: "linear-gradient(45deg, #902af0, #ed1d1d)",
                      }}
                    >
                      <i className="bi bi-instagram" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="footer-branch-sec"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <h3
                  className="m-0 fw-600 pt-2 pb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <ImLocation />
                  Branch Locations :
                </h3>
                <ul>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/4tWzcE21RpM1gdeW8"
                      target="_blank"
                    >
                      Manav Mandir
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/mp8nYdKCwRdeSPz57"
                      target="_blank"
                    >
                      Nikol
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/Q9i6t9jVromHX1X29"
                      target="_blank"
                    >
                      Surat
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/HkXfn5BXmrPKuDMz9"
                      target="_blank"
                    >
                      Anand
                    </a>
                  </li>
                </ul>
                <div class="map-container d-lg-none mb-3">
                  <div class="box">
                    <span className="box-name">Manav Mandir</span>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12349.140012101447!2d72.53275668773627!3d23.045011391639978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x605d0c100abfcc1a!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1724236290085!5m2!1sen!2sin"
                      width={"100%"}
                      height={"100%"}
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                  <div class="box">
                    <span className="box-name">Nikol</span>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.283637818638!2d72.6724874!3d23.0500607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87327045605f%3A0x3debbad8cbaa305f!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739251656844!5m2!1sen!2sin"
                      width={"100%"}
                      height={"100%"}
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div class="box">
                    <span className="box-name">Surat</span>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14883.928161878635!2d72.797916!3d21.153113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fccc4346f61%3A0x157879627c8a11cd!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739251679294!5m2!1sen!2sin"
                      width={"100%"}
                      height={"100%"}
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div class="box">
                    <span className="box-name">Anand</span>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.8793440638974!2d72.9291155!3d22.546192100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4d499fed792f%3A0xcfc4862a06d8a9a1!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739274051915!5m2!1sen!2sin"
                      width={"100%"}
                      height={"100%"}
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div
                className="footer-branch-sec"
                style={{
                  borderTop: "1px solid lightgray",
                }}
              >
                <h3 className="fw-600 mt-2 mb-3">
                  We look forward to helping you achieve your global dreams!
                </h3>
              </div>
            </div>
            <div className="col-sm-12  col-lg-4 d-none d-lg-block">
              <h3
                className="fw-600 mb-1"
                data-aos="fade-left"
                data-aos-duration="500"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <FaMapMarkedAlt />
                Location
              </h3>
              <div
                class="map-container"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <div class="box">
                  <span className="box-name">Manav Mandir</span>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12349.140012101447!2d72.53275668773627!3d23.045011391639978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac8a7faf3%3A0x605d0c100abfcc1a!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1724236290085!5m2!1sen!2sin"
                    width={"100%"}
                    height={"100%"}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
                <div class="box">
                  <span className="box-name">Nikol</span>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.283637818638!2d72.6724874!3d23.0500607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87327045605f%3A0x3debbad8cbaa305f!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739251656844!5m2!1sen!2sin"
                    width={"100%"}
                    height={"100%"}
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div class="box">
                  <span className="box-name">Surat</span>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14883.928161878635!2d72.797916!3d21.153113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fccc4346f61%3A0x157879627c8a11cd!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739251679294!5m2!1sen!2sin"
                    width={"100%"}
                    height={"100%"}
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div class="box">
                  <span className="box-name">Anand</span>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.8793440638974!2d72.9291155!3d22.546192100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4d499fed792f%3A0xcfc4862a06d8a9a1!2sCareerline%20Education%20Foundation!5e0!3m2!1sen!2sin!4v1739274051915!5m2!1sen!2sin"
                    width={"100%"}
                    height={"100%"}
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IELTSLandingPage3;
