import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeEventDialog } from "../Slice/eventDialogSlice";
import { useNavigate } from "react-router-dom";

const EventADPopup = () => {
  const eventShow = useSelector((state) => state.eventDialog.open);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectEvent = () => {
    dispatch(closeEventDialog());
    // navigate("/events/worldeducationfair/website/");
    navigate("/events/ieltsmocktest/");
    // window.open(
    //   "https://docs.google.com/forms/d/e/1FAIpQLSc945YO85F50z_XxsgnD9OV-okqxrW7ctH7HZ2Yyd43ad_KzA/viewform?usp=sf_link"
    // );
  };

  return (
    <>
      <div
        style={{ display: eventShow ? "block" : "none" }}
        className="overlay-modal"
      ></div>
      {eventShow && (
        <div className="event-ad-modal">
          <img
            src={"https://images.careerline.org/uploads/close.png"}
            height={50}
            className="event-popup-close"
            onClick={() => {
              dispatch(closeEventDialog());
            }}
          />
          <div
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            onClick={handleRedirectEvent}
          />
        </div>
      )}
    </>
  );
};

export default EventADPopup;
